import { ref } from 'vue';
import { defineStore } from "pinia";
import { useQuery } from "@urql/vue";
import { GetReportsDocument } from "@/gql/graphql";
import { ReportParsed, parseReport, unwrapReport, Annotation } from "@/helpers/health-records";
import { wiki } from '@/services/http-common';
import * as wiki_t from "@/helpers/api/wiki-v1";

export enum StoreState {
  EMPTY = "EMPTY",
  LOADING = "LOADING",
  READY = "READY",
  ERROR = "ERROR"
}

type WikiTermsResponse =
  wiki_t.paths["/terms"]["get"]["responses"][200]["content"]["application/json"];

export const useReportsStore = defineStore("reports", () => {
  const state = ref<StoreState>(StoreState.EMPTY);
  const reports = ref<ReportParsed[]>([]);
  const annotations = ref<Annotation[]>([]);
  const wikiTerms = ref<WikiTermsResponse>({});
  const wikiTermsReady = ref<boolean>(false);

  async function load(): Promise<void> {
    if (state.value === StoreState.READY) {
      return Promise.resolve();
    }
    if (state.value === StoreState.LOADING) {
      return Promise.resolve();
    }

    console.log("Store/reports: load proceeding.");
    state.value = StoreState.LOADING;
    await useQuery({
      query: GetReportsDocument
    }).then((r) => {
      if (r.data.value && !r.error.value) {
        console.log("Store/reports: loaded. Data: ", r.data);
      } else {
        console.log("Store/reports: failed to load.");
        state.value = StoreState.ERROR;
        return;
      }

      if (!r.data.value?.me.userRole) {
        state.value = StoreState.ERROR;
        throw new Error("Unexpected response format from patient reports endpoint.");
      }

      if (r.data.value?.me?.userRole?.reports) {
        console.log("Store/reports: starting parsing reports.");
        const reportsParsed = [];
        const annotationsParsed: Annotation[] = [];
        const annotationIdsParsed: Set<string> = new Set();

        for (const reportRaw of r.data.value?.me?.userRole?.reports) {
          const unwrapped = unwrapReport(JSON.parse(reportRaw.contentParsedJSON));
          //log.trace("Processing report ", unwrapped[0])
          //console.log("Unwrapped: ", JSON.stringify(unwrapped));
          const report: ReportParsed = {
            segments: [], drugs: [], ...reportRaw
          };
          report.segments = parseReport(unwrapped);
          report.drugs = []; // this is slow on backend
          report.segments.forEach((segment) => {
            if (segment.body.find((el) => el.type === "annotation")) {
              segment.body.forEach((el) => {
                if (el.type === "annotation") {
                  if (el.annotations.length > 1) console.warn("Double annotation (wrapped a elements) detected. Using just first.", segment);
                  if (el.annotations[0]?.type === 'a') {
                    console.log("Annotation parsed: ", el.annotations[0].sources);
                    for (const source of el.annotations[0].sources) {
                      if (parseInt(source) < 30000) annotationIdsParsed.add(source);
                    }
                  }
                  if (el.annotations[0]) annotationsParsed.push(el.annotations[0]);
                  if (el.annotations.find((an) => an.type === "drug")) {
                    report.drugs.push(el.annotations.find((an) => an.type === "drug") as any);
                  }
                }
              });
            }
          });
          reportsParsed.push(report);
          //console.log("Report parsed: ", report);
          //console.log("Report parsed: ", JSON.stringify(report.segments));
        }

        reports.value = reportsParsed;
        annotations.value = annotationsParsed;
        console.log("Store/reports: reports parsed. Annotations found:", annotationsParsed, [...annotationIdsParsed]);
        loadWikiTerms([...annotationIdsParsed]);
      }

      state.value = StoreState.READY;
    });
  }

  async function loadWikiTerms(termIds: string[]) {
    console.log("Loading terms from wiki: ", termIds);

    termIds.push('34805'); // LU
    const queryParam = termIds.join(",");
    const req = wiki.GET('/terms', {
      // @ts-ignore
      params: { query: { list: queryParam } }
    }).then(({ data, error }) => {
      if (data && !error) {
        console.log("Wiki result: ", data);

        const dataDeduplicated = { ...data };
        const termsByKeywords: any = {}
        for (const [k, v] of Object.entries(data)) {
          if (!v.keyword || !v.data_source) continue;
          if (termsByKeywords[v.keyword] == v.data_source) {
            delete dataDeduplicated[k];
          }
          termsByKeywords[v.keyword] = v.data_source;
        }
        
        wikiTerms.value = dataDeduplicated;
        wikiTermsReady.value = true;
      } else {
        throw new Error(`Error fetching terms: ${termIds}`);
      }
    }).catch(e => { console.error(e); throw e; });
    console.log("Wiki request: ", req);
  }

  return { reports, load, state, annotations, wikiTerms, wikiTermsReady };
});
