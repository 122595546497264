import { Report } from "@/gql/graphql";
import { DrugEntity } from "@/gql/graphql";

export type ReportParsed = Omit<Report, "patient" | "content" | "contentPreview"> & {
  segments: ReportSegment[];
  drugs: DrugEntity[];
}
export type ReportSegment = {
  head: ReportElement[];
  body: ReportElement[];
  type: 'long' | 'short' | 'lab'; // TODO: new
  labIdx?: number; // TODO: key for the lab object in metadata
}

type ReportElement = ReportElementText | ReportElementAnnotation;
type ReportElementText = {
  type: 'text';
  text: string;
}
export type ReportElementAnnotation = {
  type: 'annotation';
  text: string;
  annotations: Annotation[];
}

export type Annotation = AnnotationBase & (AnnotationDrug | AnnotationLink | AnnotationDiagnosis | AnnotationMorphology | AnnotationTopography);
type AnnotationBase = {
  text: string;
}
type AnnotationDrug = {
  type: 'drug';
  atcCode: string;
  sukl: string;
  registrationNumber: string;
  name: string;
}
type AnnotationLink = {
  type: 'a';
  sources: string[];
}
type AnnotationDiagnosis = {
  type: 'diagnosis';
  date: string;
}
type AnnotationMorphology = {
  type: 'morphology';
  date: string;
}
type AnnotationTopography = {
  type: 'topography';
  date: string;
}

// Explanation tokens
export enum ExplanationTokenType {
  DRUG = "drug",
  DIAGNOSIS = "diagnosis",
  TERM = "term"
}
export type ExplanationToken =
  | MedicineExplanationToken
  | TermExplanationToken
  | DiagnosisExplanationToken;

interface BaseExplanationToken {
  value: string;
  type: ExplanationTokenType;
  explanations: { id: string; term: string; explanation: string }[];
}

export interface MedicineExplanationToken extends BaseExplanationToken {
  type: ExplanationTokenType.DRUG;
  atcCode: string;
  sukl: string;
  registrationNumber: string;
  name: string;
}
export interface TermExplanationToken extends BaseExplanationToken {
  type: ExplanationTokenType.TERM;
}
export interface DiagnosisExplanationToken extends BaseExplanationToken {
  type: ExplanationTokenType.DIAGNOSIS;
  subType: 'dignosis' | 'morphology' | 'topography';
  date: string;
}

type ReportNode = {
  type: "element" | "text",
  name?: "h" | "b" | "s" | "a" | "drug" | "topography" | "morphology" | "diagnosis",
  attributes?: Record<string, string>,
  children?: ReportNode[],
  text?: string
}
export enum AnnotationType {
  DRUG = "drug",
  DIAGNOSIS = "diagnosis",
  MORPHOLOGY = "morphology",
  TOPOGRAPHY = "topography",
  LINK = "a"
}
const annotation_elements = ['a', 'drug', 'topography', 'morphology', "diagnosis"];

export function unwrapReport(content: any): ReportNode[] {
  if (!content.hasOwnProperty('children')) return [];
  const rootNode = content.children[0];
  if (!rootNode.hasOwnProperty('children') || rootNode['name'] !== 'report') 
    throw new Error("Unexpected root node in report content: " + rootNode);
  return rootNode.children;
}

export function parseReport(reportRootNode: ReportNode[]): ReportSegment[] {
  const segments: ReportSegment[] = [];
  //console.log("parseReport start node: " + JSON.stringify(reportRootNode));

  for (const node of reportRootNode) {
    //console.log("parseReport node: " + JSON.stringify(node));
    if (node.type === 'text' && node.text?.trim() === "") {
      continue;
    } else if (node.type === 'element' && node.name === 's') {
      // segment
      let segment: ReportSegment = { head: [], body: [], type: 'long' };
      switch(node.attributes?.t) {
        case 'l':
          segment.type = 'long'; break;
        case 's':
          segment.type = 'short'; break;
        case 'lab':
          segment.type = 'lab'; segment.labIdx = parseInt(node.attributes?.labIdx ?? '0'); break;
        default:
          throw new Error("Unexpected segment type: " + node.attributes?.t);
      }

      const headerNode = node.children?.find(n => n.type === 'element' && n.name === 'h');
      const bodyNode = node.children?.find(n => n.type === 'element' && n.name === 'b');
      if (!headerNode && !bodyNode) 
        throw new Error("Unexpected segment node without body or head children: '" + JSON.stringify(node));

      if (headerNode && headerNode?.children) {
        for (const child of headerNode.children) {
          parseReportSegmentNodes(child, segment.head);
        }
      } else {
        //console.warn("No header node.");
      }

      if (bodyNode && bodyNode?.children) {
        for (const bodyChild of bodyNode.children) {
          //console.log("Parsing body node: " + JSON.stringify(node));
          parseReportSegmentNodes(bodyChild, segment.body);
        }
      } else {
        console.warn("No body node found.");
      }

      if (segment) segments.push(segment);
    } else {
      console.error(JSON.stringify(node))
      throw new Error("Unexpected non-report node in report content, with type '" + node.type + "' and name '" + node.name+"'");
    }
  }

  return segments;
}

function parseReportSegmentNodes(n: ReportNode, result: ReportElement[]): void {
  if (n.type === 'text' && n.text) {
    result.push({text: n.text, type: 'text'});
    if (n?.children) throw new Error("Unexpected text node with children: '" + JSON.stringify(n));
    return;
  }

  if (n.type === 'element' && n.name && annotation_elements.includes(n.name)) {
    let element: ReportElement = {type: "annotation", text: '', annotations: [] as Annotation[]};

    // blacklist annotations based on stopwords dictionary
    if (n.children && n.children.length > 0 && n.children[0]?.type === 'text' && n.children[0]?.text) {
      console.log("Single text annotation: ", n.children[0]?.text);
      /* if (n.children[0]?.text == "LU") {
        console.log("LU annotation: ", n.children[0]?.text);
        // {"1":{"data_source":"NZIP","keyword":"A1AD","md_data":"**A1AD** je zkratka pro [deficit alfa-1 antitrypsinu](https://www.nzip.cz/rejstrikovy-pojem/3553).","other_data":"https://www.nzip.cz/rejstrikovy-pojem/3554","page_id":3554}}
        result.push({text: n.children[0]?.text, type: 'annotation', annotations: [{text: n.children[0]?.text, type: 'a', sources: ['34805']}]});
        return;
      } */

      if (['ac+pembroilizumab','tkáň','parciální','elevace','jt','hybridizace','fluorescenčně','centromer','portae','PNO','parenchym','počet','mm','er','akumulace','aktivita','štítnice','thyreotoxikoza','tyreotoxikoza','thyreotoxikozy','tyreostatika','tsh','promotoru','es','sr','ac+','ko','hyperthyreozu','totální','mk','uzlina','t3','tme','ihc','lu','regrese', 'multicentricity','ac','as', 'iva', 'fish', 'parafinového', 'proteinu', 'lokus', 'mamma', 'mg', 'infiltrace', 'žláza', 'axilla', 'zhmožděných', 'proliferujcí', 'na', 'pro', 'se', 'urgentní', 'onemocnění', 'navrhovaným', 'informována', 'zpracováno', 'skupinkách', 'jádro', 'onkologické', 'jednoznačných', 'standard'].includes(n.children[0]?.text.toLowerCase())) {
        // ignore and skip
        result.push({text: n.children[0]?.text, type: 'text'});
        return;
      }
    }

    element.annotations = parseElementAnnotations(n, element);
    result.push(element);
    return;
  }

  throw new Error("Unexpected node in segment, with type '" + n.type + "' and name '" + n.name+"'");
}

function parseElementAnnotations(n: ReportNode, result: ReportElement): Annotation[] {
  if (result.type != 'annotation') throw new Error("Unexpected non-annotation result: '" + JSON.stringify(result));
  if (n.type !== 'element') throw new Error("Unexpected non-element node: '" + JSON.stringify(n));
  if (!n?.children) throw new Error("Unexpected element node without children: '" + JSON.stringify(n));

  const annotations: Annotation[] = [];
  let text = "";
  if (n.children && n.children.length && n.children[0]?.type === 'text' && n.children[0]?.text) {
    text = n.children[0]?.text;
    result.text += n.children[0]?.text;
    /* [
      {"type":"text","text":"Pertuzumab+Trastuzumab+"},
      {"type":"element","name":"drug","attributes":{"atc-code":"L01CD02","sukl":"0167419","registration-number":"EU/1/07/384/003","name":"DOCETAXEL"},"children":[{"type":"text","text":"docetaxel"}]}
    ] */
    if (n.children.length > 2) console.error("Unexpected element node with more than one text child: '" + JSON.stringify(n));
  } else if (n.children && n.children.length) {
    console.log("parseElementAnnotations: Multiple children, not just text: ", JSON.stringify(n));
    /*
    {"type":"element","name":"a","attributes":{"sources":"23486,58738"},"children":[
      {"type":"element","name":"drug","attributes":{"atc-code":"L01CD01","sukl":"0104239","registration-number":"44/ 391/06-C","name":"PACLITAXEL","indication-group":"44"},"children":[
        {"type":"text","text":"paclitaxel"}
      ]}
    ]}

    {"type":"element","name":"a","attributes":{"sources":"2281,34956,37533"},"children":[{"type":"element","name":"drug","attributes":{"atc-code":"L01FF02","sukl":"0209484","registration-number":"EU/1/15/1024/002","name":"PEMBROLIZUMAB","indication-group":"44"},"children":[{"type":"text","text":"pembrolizumab"}]},{"type":"text","text":"+"},{"type":"element","name":"drug","attributes":{"atc-code":"L01CD01","sukl":"0104239","registration-number":"44/ 391/06-C","name":"PAKLITAXEL","indication-group":"44"},"children":[{"type":"text","text":"paklitaxel"}]},{"type":"text","text":"/"},{"type":"element","name":"drug","attributes":{"atc-code":"L01XA02","sukl":"0241272","registration-number":"44/ 292/09-C","name":"CBDCA","indication-group":"44"},"children":[{"type":"text","text":"cbdca"}]}]}
    */
    for (const child of n.children) {
      if (child.type === 'text') {
        //text += child.text ?? '';
        result.text += child.text;
      } else if (child.type === 'element' && child.name === 'drug') {
        if (!n.attributes) throw new Error("Unexpected drug node without attributes: '" + JSON.stringify(n));
        if (child.children && child.children.length && child.children[0]?.type === 'text' && child.children[0]?.text) {
          console.log("grandchild text: ", child.children[0]?.text);
          //text += child.children[0]?.text;
          result.text += child.children[0]?.text;
        }
        if (child.attributes && child.children) {
          annotations.push({
            text: child.children[0]?.text ?? '!undef!',
            type: 'drug',
            atcCode: child.attributes['atc-code'] ?? "", sukl: child.attributes?.sukl ?? "", 
            registrationNumber: child.attributes['registration-number'] ?? "",
            name: child.attributes?.name ?? ""
          });
        }
      } else {
        console.error("Unexpected link node with improper children: '" + JSON.stringify(n));
      }
    }
  } else {
    console.error("Unexpected link node with improper children: '" + JSON.stringify(n));
  }

  switch (n.name) {
    case 'a':
      if (!n.attributes) throw new Error("Unexpected link node without attributes: '" + JSON.stringify(n));
      annotations.push({
        text, type: 'a', sources: n.attributes?.sources?.split(',').filter(s => s != '40274') ?? []
      });
      break;
    case 'drug':
      if (!n.attributes) throw new Error("Unexpected drug node without attributes: '" + JSON.stringify(n));
      annotations.push({
        text, type: 'drug',
        atcCode: n.attributes['atc-code'] ?? "", sukl: n.attributes?.sukl ?? "", 
        registrationNumber: n.attributes['registration-number'] ?? "",
        name: n.attributes?.name ?? ""
      });
      break;
    case 'topography':
    case 'morphology':
    case 'diagnosis':
      if (!n.attributes) throw new Error("Unexpected "+n.name+" node without attributes: '" + JSON.stringify(n));
      annotations.push({
        text, type: n.name, date: n.attributes?.date ?? ""
      });
      break;
    default:
      throw new Error("Unexpected element node with name '" + n.name + "'");
  }

  for (const child of n?.children) {
    if (child.type === 'text') {
      //result.text = child.text ?? '';
    } else if (child.type === 'element') {
      // TODO: solve!
      //console.log("parseElementAnnotations: performing on child: ", JSON.stringify(child));
      //annotations.concat(parseElementAnnotations(child, result));
    }
  }
  return annotations;
}

export function parseReportContentAsText(n: ReportNode[]): string {
  const segments: string[] = [];
  for (const node of n) {
    parseReportContentAsTextRecurse(node, segments);
  }
  const res = segments.join('');
  return res;
}

function parseReportContentAsTextRecurse(n: ReportNode, result: string[]): void {
  if (n.type === 'text' && n.text) {
    if (n.type === 'text' && n.text.startsWith("Date:")) return;
    result.push(n.text);
    return;
  } else if (n?.children && Array.isArray(n.children)) {
    for (const child of n.children) {
      parseReportContentAsTextRecurse(child, result);
      result.push(' ');
    }
  }
}
